<script>
/**
 * Devices Data component
 */
import moment from "moment";
import { devicesMethods, devicesComputed, authComputed } from "@/state/helpers";
import Intervention from "./intervention";
export default {
  props: ["devices", "causes", "actions", "states", "busy", "loaderStatus"],
  components: {
    Intervention,
  },
  data() {
    return {
      device: {},
      devicesList: [],
      deviceTypes: {
        1: "REFRIGERATEUR",
        2: "CONGELATEUR",
        3: "ARMOIRES_FRIGORIFIQUE",
        4: "CHAMBRE_FROIDE",
        5: "CAMION",
      },
    };
  },
  computed: {
    ...authComputed,
    ...devicesComputed,
    fieldsComputed() {
      if (this.loggedIn.user.role != "USER") {
        return [
          { key: "serial", sortable: false, label: "Serial" },
          {
            key: "date",
            sortable: false,
            label: this.$t("dataTable.dateDisconnection.text"),
          },
          {
            key: "ref",
            sortable: false,
            label: this.$t("dataTable.reference.text"),
          },
          {
            key: "name",
            sortable: false,
            label: this.$t("dataTable.name.text"),
          },
          {
            key: "region",
            sortable: false,
            label: this.$t("dataTable.region.text"),
          },
          {
            key: "province",
            sortable: false,
            label: this.$t("dataTable.province.text"),
          },
          {
            key: "stockType",
            sortable: false,
            label: this.$t("dataTable.stockType.text"),
          },
          {
            key: "vaccinTypes",
            sortable: false,
            text: this.$t("dataTable.vaccinType.text"),
          },
          {
            key: "type",
            sortable: false,
            label: this.$t("dataTable.type.text"),
          },
        ];
      } else {
        return [
          { key: "serial", sortable: false, label: "Serial" },
          {
            key: "date",
            sortable: false,
            label: this.$t("dataTable.dateDisconnection.text"),
          },
          {
            key: "ref",
            sortable: false,
            label: this.$t("dataTable.reference.text"),
          },
          {
            key: "name",
            sortable: false,
            label: this.$t("dataTable.name.text"),
          },
          {
            key: "region",
            sortable: false,
            label: this.$t("dataTable.region.text"),
          },
          {
            key: "province",
            sortable: false,
            label: this.$t("dataTable.province.text"),
          },
          {
            key: "stockType",
            sortable: false,
            label: this.$t("dataTable.stockType.text"),
          },
          {
            key: "vaccinTypes",
            sortable: false,
            text: this.$t("dataTable.vaccinType.text"),
          },
          {
            key: "type",
            sortable: false,
            label: this.$t("dataTable.type.text"),
          },
        ];
      }
    },
  },
  mounted() {},
  methods: {
    ...devicesMethods,
    showModalAquit() {
      this.$bvModal.show("intervention-modal");
    },
    hideModalAquit() {
      this.$bvModal.hide("intervention-modal");
    },
    inter(intervention) {
      let interventionFilter = this.cleanObject(intervention);
      this.interventionOffline(interventionFilter).then((device) => {
        this.makeToast("Intervention", device.name, "success")
        this.hideModalAquit();
      });
    },
    passDevice(device) {
      this.device = device;
      this.showModalAquit();
    },
    cleanObject(obj) {
      for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName];
        }
      }
      return obj;
    },
    makeToast(title, msg, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    rowClicked(record) {
      this.$router.push({
        name: "device",
        params: { serial: record.serial },
      });
    },
  },
  watch: {
    devices(newVal) {
      this.devicesList = [];
      newVal.map((obj) => {
        let vaccinTypes = obj.vaccinTypes.map(vaccin=> {return vaccin.name});
        this.devicesList.push({
          ...obj,
          date: obj.disconnectedAt ? moment(obj.disconnectedAt).format("DD-MM-YYYY HH:mm:ss") : "-",
          region: obj.region.name,
          province: obj.province.name,
          vaccinTypes: vaccinTypes ? vaccinTypes.toString() : "-"
        });
      });
    },
  },
};
</script>

<template>
  <div
    class="table-responsive mb-3 bg-white rounded-lg"
    style="min-height: 430px"
  >
    <b-table
      class="mb-0"
      hover
      show-empty
      centred
      :busy="busy"
      :empty-text="$t('dataTable.noDevices.text')"
      :items="devicesList"
      :fields="fieldsComputed"
      v-bind:style="{ cursor: `pointer` }"
      stacked="sm"
      @row-clicked="rowClicked"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <strong>{{ $t("dataTable.loading.text") }}</strong>
        </div>
      </template>
      <template v-slot:cell(type)="row">
        <div class="font-size-14 font-weight-bold">
          {{deviceTypes[row.item.type]}}
        </div>
      </template>
      <!-- <template v-slot:cell(actions)="data">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12">
            <b-button
              type="button"
              class="ml-2"
              variant="secondary"
              @click="passDevice(data.item)"
              >Intervention
            </b-button>
          </div>
        </div>
      </template> -->
    </b-table>
    <Intervention
      v-bind:device="device"
      :causes="causes"
      :actions="actions"
      :states="states"
      @validIntervention="inter"
    />
  </div>
</template>
<style>
.tdClass {
  text-align: center;
}
</style>